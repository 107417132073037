// src/components/CelebritySection.js
import React from "react";
import "./CelebritySection.css";

const CelebritySection = () => {
  return (
    <section className="page-container">
      <section className="celebrity-section">
        <div className="celebrity-content">
          <img
            src="/images/srk.jpeg"
            alt=""
            className="celebrity-image"
            style={{ maxWidth: '200px', height: 'auto' }}
          />
          <div className="celebrity-text">
            <h2>Shahrukh Khan</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <a href="https://play.google.com/store/games?hl=en&gl=US" className="chat-now-button" style={{textDecoration: 'none'}}>
  Chat Now
</a>

          </div>
        </div>
      </section>

      {/* Four cards under the celebrity section */}
      <div className="card-container">
      <a
  href="https://play.google.com/store/games?hl=en&gl=US"
  className="card"
>
  <img
    src="/images/chatlogo2.png"
    alt="Logo 1"
    className="card-logo"
    style={{ maxWidth: '90px', height: 'auto' }}
  />
  <h3>Chat with astrologer</h3>
</a>


<a
  href="https://play.google.com/store/games?hl=en&gl=US"
  className="card"
>
  <img
    src="/images/callinglogo.png"
    alt="Logo 1"
    className="card-logo"
    style={{ maxWidth: '120px', height: 'auto' }}
  />
  <h3>Talk to astrologer</h3>
</a>

<a
  href="https://play.google.com/store/games?hl=en&gl=US"
  className="card"
>
  <img
    src="/images/shop.png"
    alt="Logo 1"
    className="card-logo"
    style={{ maxWidth: '90px', height: 'auto' }}
  />
  <h3>Astromall shop</h3>
</a>

<a
  href="https://play.google.com/store/games?hl=en&gl=US"
  className="card"
>
  <img
    src="/images/pooja.jpeg"
    alt="Logo 1"
    className="card-logo"
    style={{ maxWidth: '90px', height: 'auto' }}
  />
  <h3>Book a pooja</h3>
</a>
      </div>
    </section>
  );
};

export default CelebritySection;
