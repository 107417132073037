// src/components/AstrologyNewsSection.js
import React from "react";
import "./AstrologyNewsSection.css"; // Create a new CSS file for styling

const AstrologyNewsSection = () => {
  // Mock data for news articles, you can replace it with real data
  const newsArticles = [
    { title: "Article 1 Title", content: "Article 1 Content" },
    { title: "Article 2 Title", content: "Article 2 Content" },
    { title: "Article 3 Title", content: "Article 3 Content" },
    { title: "Article 4 Title", content: "Article 4 Content" },
  ];

  return (
    <section className="astrology-news-section">
      <h2>Astrology in News</h2>
      <div className="news-container">
        {newsArticles.map((article, index) => (
          <div className="news-card" key={index}>
            <h3>{article.title}</h3>
            <p>{article.content}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AstrologyNewsSection;
