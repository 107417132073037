import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer2 = () => {
  const animationStyles = `
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;

  return (
    <footer style={styles.footer}>
      <style>{animationStyles}</style>
      <div style={styles.row}>
        <div style={styles.footerSection}>
          <h2 style={styles.sectionHeading}>Horoscope</h2>
          <ul style={{ ...styles.list, animation: 'fadeInUp 1s ease-in-out' }}>
            <li>Horoscope 2024</li>
            <li>Today's Horoscope</li>
            <li>Today's Horoscope</li>
            <li>Today's Horoscope</li>
            <li>Today's Horoscope</li>
            <li>Today's Horoscope</li>
            <li>Today's Horoscope</li>
            <li>Today's Horoscope</li>
            <li>Today's Horoscope</li>
            <li>Today's Horoscope</li>
            {/* ... other horoscope items ... */}
          </ul>
        </div>

        <div style={styles.footerSection}>
          <h2 style={styles.sectionHeading}>Shubh Muhurat 2024</h2>
          <ul style={{ ...styles.list, animation: 'fadeInUp 1s ease-in-out' }}>
            {/* ... other shubh muhurat items ... */}
            <li>annaprasan muhurat 2024</li>
            <li>annaprasan muhurat 2024</li>
            <li>annaprasan muhurat 2024</li>
            <li>annaprasan muhurat 2024</li>
            <li>annaprasan muhurat 2024</li>
            <li>annaprasan muhurat 2024</li>
            <li>annaprasan muhurat 2024</li>
            <li>annaprasan muhurat 2024</li>
            <li>annaprasan muhurat 2024</li>
          </ul>
        </div>

        <div style={styles.footerSection}>
          <h2 style={styles.sectionHeading}>Important Links</h2>
          <ul style={{ ...styles.list, animation: 'fadeInUp 1s ease-in-out' }}>
            {/* ... other important links ... */}
            <li>collaboration</li>
            <li>tarot</li>
            <li>vastu sastra</li>
            <li>vastu sastra</li>
            <li>vastu sastra</li>
            <li>vastu sastra</li>
            <li>vastu sastra</li>
            <li>vastu sastra</li>
            <li>vastu sastra</li>
            <li>vastu sastra</li>
           

          </ul>
        </div>

        <div style={styles.footerSection}>
          <h2 style={styles.sectionHeading}>Corporate Info</h2>
          <ul style={{ ...styles.list, textAlign: 'justify', lineHeight: '2.5', animation: 'fadeInUp 1s ease-in-out' }}>
            {/* ... other corporate info items ... */}
            <li>Terms&condition</li>
            <li>privacy policy</li>
            <li>disclaimer</li>
            <li>about us</li>
            <li>pricing policy</li>
       
          </ul>
          <div style={styles.contactInfo}>
            <h2>Contact Us</h2>
            <p>We are available 24x7 on chat support</p>
            <p>Email ID:contact@astrotalk.com</p>
            <p>We are available 24x7 on chat support</p>
             {/* Social Media Icons */}
             <div style={styles.socialIcons}>
  <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
    <FaFacebook style={{ ...styles.socialIcon, fontSize: "3em" }} />
  </a>
  <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
    <FaInstagram style={{ ...styles.socialIcon, fontSize: "3em" }} />
  </a>
  <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
    <FaYoutube style={{ ...styles.socialIcon, fontSize: "3em" }} />
  </a>
</div>
          </div>
        </div>
      </div>

      <div style={styles.row}>
        <div style={{ ...styles.footerSection, textAlign: 'center' }}>
          <h2 style={styles.sectionHeading}></h2>
        </div>
        <div style={{ ...styles.footerSection, textAlign: 'center' }}>
          <h2 style={styles.sectionHeading}></h2>
          <div style={styles.appLogos}>
            <img src="/images/android.webp" alt="Android App" style={styles.appLogo} />
            <img src="/images/ios.webp" alt="iOS App" style={styles.appLogo} />
          </div>
        </div>
      </div>
        {/* Copyright Section */}
        <div style={{ ...styles.row, justifyContent: 'center', marginTop: 'auto' }}>
        <div style={{ ...styles.footerSection, width: '100%' }}>
          <div style={styles.copyrightContainer}>
            <p style={styles.copyright}>© 2024 12thRashi. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};


const styles = {
  footer: {
    backgroundColor: 'orange',
    padding: '20px',
    textAlign: 'center',
    position: 'relative',
    
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  footerSection: {
    width: '200px',
    marginBottom: '20px',
    marginRight: '20px'
   
  },
  sectionHeading: {
    fontSize: '1.2em',
    color: 'yellow', 
    textDecoration: 'underline',
    paddingBottom: '2px', 
    marginTop: '5px'
  
  },
  list: {
    lineHeight: '2.5',
    color: 'black',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    animation: 'fadeInUp 1s ease-in-out',
  },
  contactInfo: {
    marginTop: '-100px',
    color: 'black',
    textAlign:'justify',
    position:'relative',
    top:'95px'
    
    
  },
  appLogos: {
   
    display: 'flex',
    justifyContent: 'center',
  },
  appLogo: {
    width: '150px',
    margin: '0 5px',
    marginTop: '20px',
   
  },
  socialIcons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  socialIcon: {
    width: '30px',
    margin: '0 10px',
  },
  copyrightContainer: {
    backgroundColor: '#000000',  // Black background color
    padding: '20px',  // Adjusted padding for better spacing
    textAlign: 'center',
    position: 'relative',
    bottom: '-117px',  // Adjusted position to be at the bottom
    margin: '0', 
    marginLeft: '-21px',
    marginRight: '-40px',

  },
  copyright: {
    color: 'white',
    fontSize: '0.9em',
    margin: 0, 
    marginRight:'-40px',
    marginLeft: '-21px'
  
  },
};


export default Footer2;
