// src/components/CountingSection.js
import React from "react";
import "./CountingSection.css";

const CountingSection = () => {
  // You can replace these numbers with the actual counts from your data or state
  const totalAstrologers = 50;
  const totalChats = 1000;
  const totalCustomers = 5000;

  return (
    <section className="counting-section">
      <div className="counting-card-container">
        <div className="counting-card">{totalAstrologers}+ Astrologers</div>
        <div className="counting-card">{totalChats}+ Chats</div>
        <div className="counting-card">{totalCustomers}+ Customers</div>
      </div>
    </section>
  );
};

export default CountingSection;
