// src/components/WhyAstrologySection.js
import React from "react";
import "./AstrologyAccuracyFAQ.css";

const WhyAstrologySection = () => {
  return (
    <section className="why-astrology-section">
      <h2>FAQ'S ABOUT ASTROLOGY</h2>
      <h4>Why Is Astrology So Accurate?</h4>
      <p>
      First of all, the credit for astrology and the predictions we make here being so accurate goes to the 100s of learned and astrologically-versed astrologers we have on board. And to answer what makes astrology so accurate, then it is the pure knowledge of our astrologers about the planets, planetary configurations, constellations and learning of how they influence human life and behaviour as they move, shift or even stay still. Astrology is not some random guesswork but science that entails scientific principles that were sketched by learned Gurus 1000s of years ago and have not changed to date. These gurus studied the movement of planets and how their aura influences the life of people on earth. The antiquity of astrology is revealed from the fact that millions of people continue to stay connected with it due to the accuracy it has to offer. Astrology has changed the lives of millions of people through us and many other astrologers who continue to share their knowledge of astrology one prediction at a time.
      </p>
      <p>
        If one has to go into the technicalities of astrology, it is the study of different cosmic objects, which are usually stars and planets, that have an effect on the lives of the people.
      </p>
      {/* Add more paragraphs as needed */}
      <div className="highlighted-points">
        <p>
          <span className="highlight">Why Should You Choose Astrotalk For An Astrology Horoscope?:</span>

          From 4.8/5 Google Rating, 5/5 Facebook Rating to appreciation across various other platforms, Astrotalk, over the years, has garnered a wide user base that is nothing but satisfied with all the astrology related material we have to offer. The process of recruiting the astrologers on the Astrotalk platform is really stringent and only the best astrologers can make it to the platform only to ensure you're getting the best service each and every time you get along with us.

The services we are talking about range across online Kundli matching, horoscope predictions, daily horoscope, weekly horoscope, numerology, tarot reading, shopping, free blogs, and many more services.

Moreover, in case you bump into any sort of problem, you can directly connect with the CEO to register the same with us. Providing exceptional service is our main motto and we ensure that is achieved whatsoever.
        </p>
        <p>
          <span className="highlight">Is Astrology Prediction True?:</span> Many people have misconceptions that an astrologer can tell them what exactly is going to happen with them in the future or next moment, which is a wrong way to think about astrology. Astrology predictions are based on planetary movements. A planet moves from one house to another, one zodiac sign to another, etc. These moments by plantes can either be good or unfavourable for the native. For example, if say the planet Mercury is camping in a friendly zodiac sign, the outcomes are to be good for the native. Meanwhile, if the mercury is camping in an unfriendly zodiac sign, the outcome can be critical. An astrologer, based on calculations, notes down such shifts and traits and based on the same share with you if your upcoming time would be good or tough. If you think this way, astrology predictions ought to be true. Accurate predictions are possible when you seek to know about things like upcoming shubh muhurats, whether you are manglik or not?, etc.

        </p>
        <p>
          <span className="highlight">How Can Online Astrology Help Me In Predicting The Future?:</span>
          Online astrology can be of two types. One, you can visit a website and find astrology tools available on the same. Then all you need to do is enter some details like your date of birth, time of your birth, place of birth, etc. and you will have for yourself a contentful report describing you better than anyone else ever would. Meanwhile, the other way around is to directly get in touch with the astrologers (over a call or chat) we have on board to get the same information in a much detailed manner. Online astrology may look modern but when it comes to making predictions about your future, the old school way of calculating the position of the stars and planets is used to provide you the most accurate results. Simply say, online astrology is just a change of mode of communication between an astrologer and the one who seeks to know about their past, present and future. However, the ways to come to these answers is still old school and one one of the most authentic one.
        </p>
        <p>
          <span className="highlight">What Kind Of Questions Can I Ask To Online Astrologers?:</span>
          Until you are being respectful and authentic about your queries, there isn't a limit to what you can chat with an astrologer and what you can’t. Astrology has an answer for literally anything and everything. Starting right from your career to your love life or health, astrology can predict endless queries. You can trust us when we say our astrologers have been inundated with some of the most difficult questions, which they have answered with ease.

Are you a manglik? An astrologer can help you find that. Or what the shubh muhurat for getting married? Or what are the chances of you improving your financial position in the years to come? Our astrologers can answer all such questions by simply calculating the position of the planets and combining them with numerous factors like your nakshatra, zodiac signs, houses in astrology and more.


        </p>
        {/* Add more highlighted points as needed */}
      </div>
    </section>
  );
};

export default WhyAstrologySection;
