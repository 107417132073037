// src/components/CelebrityCustomerSection.js
import React, { useState } from "react";
import "./CelebrityCustomerSection.css";

const CelebrityCustomerSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Mock data for YouTube videos, you can replace it with real data
  const youtubeVideos = [
    "video_id_1",
    "video_id_2",
    "video_id_3",
    // Add more video IDs as needed
  ];

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % youtubeVideos.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? youtubeVideos.length - 1 : prevSlide - 1
    );
  };

  return (
    <section className="celebrity-customer-section">
      <h2>Celebrity Customers</h2>
      <div className="video-slider">
        <div className="video-cards-container">
          <div className="video-cards">
            {youtubeVideos.map((videoId, index) => (
              <div
                className={`video-card ${index === currentSlide ? "active" : ""}`}
                key={index}
              >
                {/* Replace "your-youtube-api-key" with your actual YouTube API key */}
                <iframe
                  title={`video-${index}`}
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
          <div className="slide-controls left" onClick={handlePrevSlide}>
            &#9665;
          </div>
          <div className="slide-controls right" onClick={handleNextSlide}>
            &#9655;
          </div>
        </div>
      </div>
    </section>
  );
};

export default CelebrityCustomerSection;
