// src/components/LatestFromBlogSection.js
import React, { useState } from "react";
import "./LatestFromBlogSection.css";

const LatestFromBlogSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Mock data for blog posts, you can replace it with real data
  const blogPosts = [
    { title: "Post 1 Title", content: "Post 1 Content" },
    { title: "Post 2 Title", content: "Post 2 Content" },
    { title: "Post 3 Title", content: "Post 3 Content" },
    // Add more blog posts as needed
  ];

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % blogPosts.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? blogPosts.length - 1 : prevSlide - 1
    );
  };

  return (
    <section className="latest-from-blog-section">
      <h2>Latest from Blog</h2>
      <div className="blog-slider">
        <div className="blog-cards-container">
          <div className="blog-cards">
            {blogPosts.map((post, index) => (
              <div
                className={`blog-card ${index === currentSlide ? "active" : ""}`}
                key={index}
              >
                <h3>{post.title}</h3>
                <p>{post.content}</p>
              </div>
            ))}
          </div>
          <div className="slide-controls left" onClick={handlePrevSlide}>
            &#9665;
          </div>
          <div className="slide-controls right" onClick={handleNextSlide}>
            &#9655;
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestFromBlogSection;
