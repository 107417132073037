// src/components/AstrologerSection.js
import React, { useState } from "react";
import "./AstrologerSection.css";

const AstrologerSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Mock data for astrologers, you can replace it with real data
  const astrologers = [
    { name: "Astrologer 1", qualification: "Ph.D. in Astrology", image: "astrologer1.jpg" },
    { name: "Astrologer 2", qualification: "Master's in Vedic Astrology", image: "astrologer2.jpg" },
    { name: "Astrologer 3", qualification: "Certified Astrology Practitioner", image: "astrologer3.jpg" },
    
    // Add more astrologers as needed
  ];

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % astrologers.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? astrologers.length - 1 : prevSlide - 1
    );
  };

  return (
    <section className="astrologer-section">
      <h2>Our Astrologers</h2>
      <div className="astrologer-slider">
        <div className="astrologer-cards-container">
          <div className="astrologer-cards">
            {astrologers.map((astrologer, index) => (
              <div
                className={`astrologer-card ${index === currentSlide ? "active" : ""}`}
                key={index}
              >
                <img src={`images/${astrologer.image}`} alt={`Astrologer ${index + 1}`} />
                <div className="astrologer-info">
                  <h3>{astrologer.name}</h3>
                  <p>{astrologer.qualification}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="slide-controls left" onClick={handlePrevSlide}>
            &#9665;
          </div>
          <div className="slide-controls right" onClick={handleNextSlide}>
            &#9655;
          </div>
        </div>
      </div>
    </section>
  );
};

export default AstrologerSection;
