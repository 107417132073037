import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.aboutAstrotalk}>
        <h2 style={styles.heading}>About 12Rashi</h2>
        <p style={styles.infoWithUnderline}>
          12thRashi is the best astrology website for online Astrology predictions. Talk to Astrologer on call and get answers to all your worries by seeing the future life through Astrology Kundli Predictions from the best Astrologers from India. Get best future predictions related to Marriage, love life, Career or Health over call, chat, query or report.
        </p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: 'orange',
    padding: '20px',
    textAlign: 'center',
    position: 'relative',
    top: '0',
  },
  aboutAstrotalk: {
    textAlign: 'left',
    marginBottom: '15px',
  },
  heading: {
    textDecoration: 'underline',
    fontSize: '1.2em',
    color: 'white',
  },
  infoWithUnderline: {
    lineHeight: '1.6',
    color: 'white',
    borderBottom: '1px solid white',
    paddingBottom: '5px', // Add space between text and underline if needed
  },
};

export default Footer;
