// src/App.js
import React, { useState } from "react";
import "./App.css";

import CelebritySection from "./components/CelebritySection";
import CountingSection from "./components/CountingSection";
import AstrologyNewsSection from "./components/AstrologyNewsSection";
import LatestFromBlogSection from "./components/LatestFromBlogSection";
import CelebrityCustomerSection from "./components/CelebrityCustomerSection";
import AstrologerSection from "./components/AstrologerSection";
import WhyAstrologySection from "./components/WhyAstrologySection";
import AstrologyAccuracyFAQ from "./components/AstrologyAccuracyFAQ";
import Footer from "./components/Footer";
import Foooter2 from "./components/Foooter2";

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [selectedHoroscope, setSelectedHoroscope] =
    useState("Choose Horoscope");
  const [dropdownLanguageVisible, setDropdownLanguageVisible] = useState(false);
  const [dropdownHoroscopeVisible, setDropdownHoroscopeVisible] =
    useState(false);

  const toggleDropdown = (dropdownType) => {
    if (dropdownType === "language") {
      setDropdownLanguageVisible(!dropdownLanguageVisible);
      setDropdownHoroscopeVisible(false);
    } else if (dropdownType === "horoscope") {
      setDropdownHoroscopeVisible(!dropdownHoroscopeVisible);
      setDropdownLanguageVisible(false);
    }
  };

  const selectLanguage = (language) => {
    setSelectedLanguage(language);
    setDropdownLanguageVisible(false);
  };

  const selectHoroscope = (horoscope) => {
    setSelectedHoroscope(horoscope);
    setDropdownHoroscopeVisible(false);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="logo">Your Logo</div>
        <ul className="nav-links">
          <li className="right">
            <a href="https://play.google.com/store/games?hl=en&gl=US">Free Kundli</a>
          </li>
          <li className="right">
            <a href="https://play.google.com/store/games?hl=en&gl=US">Kundli Matching</a>
          </li>
          <li className="right">
            <a href="https://play.google.com/store/games?hl=en&gl=US">Chat with Astrologer</a>
          </li>
          <li className="right">
            <a href="https://play.google.com/store/games?hl=en&gl=US">Talk to astrologer</a>
          </li>
          <li className="right">
            <a href="https://play.google.com/store/games?hl=en&gl=US">Book a puja</a>
          </li>
          <li className="right">
            <a href="https://play.google.com/store/games?hl=en&gl=US">Astromall</a>
          </li>
          <li className="right">
            <a href="https://play.google.com/store/games?hl=en&gl=US">Blog</a>
          </li>
          <div className="right"></div>

          <li
            className="dropdown"
            onMouseEnter={() => toggleDropdown("horoscope")}
            onMouseLeave={() => toggleDropdown("horoscope")}
          >
            <a href="/dropdown">
              {selectedHoroscope} {/* Show the selected horoscope */}
              <span className="dropdown-icon">&#9660;</span>
            </a>
            {dropdownHoroscopeVisible && (
              <div className="dropdown-content">
                <a onClick={() => selectHoroscope("Aries")}>Aries</a>
                <a onClick={() => selectHoroscope("Taurus")}>Taurus</a>
                <a onClick={() => selectHoroscope("Gemini")}>Gemini</a>
                {/* Add more horoscope options as needed */}
              </div>
            )}
          </li>
          <li
            className="dropdown"
            onMouseEnter={() => toggleDropdown("language")}
            onMouseLeave={() => toggleDropdown("language")}
          >
            <a href="/dropdown">
              {selectedLanguage} {/* Show the selected language */}
              <span className="dropdown-icon">&#9660;</span>
            </a>
            {dropdownLanguageVisible && (
              <div className="dropdown-content">
                <a onClick={() => selectLanguage("English")}>English</a>
                <a onClick={() => selectLanguage("Bengali")}>Bengali</a>
                <a onClick={() => selectLanguage("Hindi")}>Hindi</a>
              </div>
            )}
          </li>
        </ul>
      </nav>
      <CelebritySection />

      {/* Complementary Astrology Services Section */}
      <section className="complementary-section">
        <h2>Complementary Astrology Services</h2>
        <div className="card-container1">
  <div className="card1">
    <div className="logo">
      <img src="images/horoscope.jpeg" alt="Logo" style={{ maxWidth: '100px', height: 'auto',marginLeft:'27px' }} />
    
    </div>
    <h3>Todays Horoscope</h3>
    <p>Card 1 Description</p>
  </div>

  <div className="card1">
    <div className="logo">
      <img src="images/kundlilogo.png" alt="Logo" style={{ maxWidth: '100px', height: 'auto',marginLeft:'27px' }} />
    </div>
    <h3>Free Kundli</h3>
    <p>Card 2 Description</p>
  </div>

  <div className="card1">
    <div className="logo">
      <img src="images/compatibility.png" alt="Logo" style={{ maxWidth: '100px', height: 'auto',marginLeft:'27px' }} />
    </div>
    <h3>Compatibility</h3>
    <p>Card 3 Description</p>
  </div>

  {/* <div className="card1">
    <div className="logo">
      <img src="images/diya.png" alt="Logo" style={{ maxWidth: '100px', height: 'auto',marginLeft:'27px' }} />
    </div>
    <h3>Festival</h3>
    <p>Card 4 Description</p>
  </div> */}
</div>

      </section>

      <CountingSection />
      <AstrologyNewsSection />
      <LatestFromBlogSection />
      <CelebrityCustomerSection />
      <AstrologerSection />
      <WhyAstrologySection />
      <AstrologyAccuracyFAQ />
      <Footer />
      <Foooter2 />
    </div>
  );
}

export default App;
